@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@font-face {
  font-family: Mialgor;
  src: url("./assets/Font/Mialgor.ttf");
}

.marketing {
  font-family: Mialgor;
  overflow-x:hidden ;
}
/* button {
  font-family: Mialgor;
} */
.marketing input,
.marketing textarea {
  font-family: 'Poppins', sans-serif ;
}
.marketing h1,
.marketing h2,
.marketing h3,
.marketing h4,
.marketing h5,
.marketing h6 {
  font-family: Mialgor !important;
}
.marketing p {
  font-family: 'Poppins', sans-serif ;
}
.marketing a {
  font-family: Mialgor;
}

.marketing ol > li {
  font-family: 'Poppins', sans-serif !important
}

.marketing ul > li {
  font-family: 'Poppins', sans-serif !important;
}

.marketing .stickyNavbar{
     position: fixed;
     top: 0;
     z-index: 999;
     transition: 0.5s all;
    
}

.marketing .css-5wjzvj {
  background: none !important;
}

.marketing .card-shadow {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
}

.marketing .link {
  position: relative;
}

.marketing .link::after {
  content: "";
  position: absolute;
  background-color: #bbbbbb;
  top: 50%;
  width: 3px;
  height: 100%;
  right: -11px;
  transform: translateY(-53%);
}

